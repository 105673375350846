import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAvatar, INavigationMenu } from '@core/models';
import { AuthRedirectionService, AuthService } from '@core/services/auth';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {
  @Input() styleClass: string | undefined;
  @Input() isCollapsed = false;
  @Output() closeEvent = new EventEmitter<boolean>();

  title = 'Associate Professor';
  fullName = 'Chris Roelfsema';
  jobTitle = 'Principal Research Fellow & Academic';

  avatar: IAvatar = {
    url: 'https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(0).webp',
    name: 'Chris Roelfsema',
    round: true
  };

  menus: INavigationMenu[] = [
    {
      name: 'dashboard',
      label: 'Dashboard',
      i18n: 'LAYOUTS.SHELL_LEFT_SIDEBAR.DASHBOARD',
      route: '/dashboard',
      icon: 'hi hi-home'
    },
    {
      name: 'insights',
      label: 'Insights',
      i18n: 'LAYOUTS.SHELL_LEFT_SIDEBAR.INSIGHTS',
      route: '/insights',
      icon: 'hi hi-chart-pie'
    },
    {
      name: 'my-projects',
      label: 'My Projects',
      i18n: 'LAYOUTS.SHELL_LEFT_SIDEBAR.MY_PROJECTS',
      route: '/my-projects',
      hasCounter: true,
      icon: 'hi hi-files'
    },
    {
      name: 'my-evidence',
      label: 'My Evidence Vault',
      i18n: 'LAYOUTS.SHELL_LEFT_SIDEBAR.MY_EVIDENCE',
      route: '/my-evidence',
      icon: 'hi hi-lock'
    },
    {
      name: 'impactreviewer',
      label: 'ImpactReviewer',
      i18n: 'LAYOUTS.SHELL_LEFT_SIDEBAR.IMPACT_REVIEWER',
      icon: 'hi-brand hi-ir-outline',
      styleClass: 'ir',
      hasCounter: true,
      subMenus: [
        {
          name: 'myreviews',
          label: 'My Reviews',
          i18n: 'NAVIGATIONS.MY_REVIEWS',
          route: '/reviewer/my-reviews'
        },
        {
          name: 'newreview',
          label: 'New Review',
          i18n: 'NAVIGATIONS.NEW_REVIEW',
          route: '/reviewer/new-review'
        },
        {
          name: 'allreviews',
          label: 'All Reviews',
          i18n: 'NAVIGATIONS.ALL_REVIEWS',
          route: '/reviewer/all-reviews'
        }
      ]
    }
  ];

  profileUrl!: string;

  constructor(
    private authService: AuthService,
    private authRedirectionService: AuthRedirectionService
  ) {}

  ngOnInit() {
    this.profileUrl = this.authRedirectionService.profileUrl;
  }

  onClose() {
    this.closeEvent.emit(true);
  }

  async onSignOut(): Promise<void> {
    await this.authService.signOut();
  }
}
