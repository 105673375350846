<header>
  <nav
    class="navbar {{isCollapsed ? 'compacted' : 'expanded'}} navbar-expand navbar-dark fixed-top dark"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img alt="ImpactTracker-logo" src="/assets/brand/logo-reversed.svg" />
      </a>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav nav-left me-auto">
          <li class="nav-item text-nowrap">
            <a class="nav-link slider" type="button" (click)="onToggle()">
              <i class="hi hi-list"></i>
              <span class="slider-text">{{ currentNavigationName }}</span>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav nav-right">
          <li class="nav-item">
            <div class="input-group search">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'LAYOUTS.SHELL_TOP_NAVBAR.SEARCH' | translate }}"
                aria-label="Search"
                aria-describedby="search"
              />
              <span class="input-group-append">
                <div class="input-group-text"><i class="hi hi-search"></i></div>
              </span>
            </div>
          </li>
          <li *ngIf="currentNavigationButton" class="nav-item">
            <a
              href="{{currentNavigationButton.route}}"
              type="button"
              class="btn btn-primary btn-nav"
            >
              <i class="hi hi-add"></i>
              <span class="btn-text">{{ currentNavigationButton.name }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link help"
              pTooltip="{{ 'LAYOUTS.SHELL_TOP_NAVBAR.HELP' | translate }}"
              tooltipPosition="bottom"
            >
              <i class="hi hi-c-question-outline"></i>
            </a>
          </li>
          <li class="nav-item">
            <app-notification-dropdown></app-notification-dropdown>
          </li>
          <li class="nav-item">
            <div class="v-divider"></div>
          </li>
          <li class="nav-item">
            <app-apps-dropdown></app-apps-dropdown>
          </li>
          <li class="nav-item">
            <div class="v-divider"></div>
          </li>
          <li class="nav-item">
            <app-organisation-dropdown></app-organisation-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
