import { CommonModule, KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PrimengModule } from './primeng.module';
import {
  AvatarComponent,
  LanguageSelectorComponent,
  LoaderComponent,
  DataTableComponent,
  FormTextControlComponent,
  FormSearchControlComponent,
  FormDateRangeControlComponent,
  FormSdgsControlComponent,
  FormAutocompleteControlComponent,
  WhitespaceComponent,
  FormCheckboxGroupControlComponent,
  FormRadioControlComponent
} from './components';
import { FullContentDirective, ImgFallbackDirective } from './directives';
import { TruncatePipe } from './pipes';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, PrimengModule],
  declarations: [
    LanguageSelectorComponent,
    LoaderComponent,
    AvatarComponent,
    FormAutocompleteControlComponent,
    FormCheckboxGroupControlComponent,
    FormDateRangeControlComponent,
    FormRadioControlComponent,
    FormSdgsControlComponent,
    FormSearchControlComponent,
    FormTextControlComponent,
    DataTableComponent,
    WhitespaceComponent,
    // Directives
    FullContentDirective,
    ImgFallbackDirective,
    // Pipes
    TruncatePipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PrimengModule,
    // Components
    AvatarComponent,
    LanguageSelectorComponent,
    LoaderComponent,
    FormAutocompleteControlComponent,
    FormCheckboxGroupControlComponent,
    FormDateRangeControlComponent,
    FormRadioControlComponent,
    FormSdgsControlComponent,
    FormSearchControlComponent,
    FormTextControlComponent,
    DataTableComponent,
    WhitespaceComponent,
    // Directives
    FullContentDirective,
    ImgFallbackDirective,
    // Pipes
    TruncatePipe
  ],
  providers: [KeyValuePipe]
})
export class SharedModule {}
