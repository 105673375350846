import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IOrganisationMenu } from '@core/models';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  constructor(private http: HttpClient) {}

  getOrganisationMenu() {
    return this.http
      .get<IOrganisationMenu[]>('assets/tests/organisations.json')
      .pipe(map(res => res));
  }
}
