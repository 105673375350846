<div class="input-group">
  <span class="input-group-text" id="search">
    <i class="{{icon}}"></i>
  </span>
  <input
    type="text"
    pInputText
    class="form-control"
    [class.has-filter]="filters.length"
    placeholder="{{placeholder}}"
    [attr.aria-label]="placeholder"
    aria-describedby="search"
    (input)="onInput($event)"
  />
  <!-- Filter -->
  <ng-container *ngIf="filters.length">
    <ng-container *ngFor="let filter of filters; let last = last">
      <button
        type="button"
        class="btn btn-filter dropdown-toggle dropdown-toggle-split selected-options"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        [attr.data-selected-options]="getFilterText(filter.name)"
      >
        {{ getFilterText(filter.name) }}
      </button>
      <div class="dropdown-menu dropdown-menu-end">
        <div class="dropdown-item" *ngFor="let option of filter.options">
          <input
            class="form-check-input"
            type="checkbox"
            id="{{filter.name}}-{{option.value}}"
            [value]="option.value"
            (change)="toggleFilter(filter.name, option)"
            [checked]="isSelected(filter.name, option)"
          />
          <label
            class="form-check-label"
            for="{{filter.name}}-{{option.value}}"
            [innerHTML]="option.label"
          ></label>
        </div>
      </div>
      <div class="divider" *ngIf="filters.length > 1 && !last">
        <div class="vl"></div>
      </div>
    </ng-container>
  </ng-container>
</div>
