import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ShellComponent } from './shell.component';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import {
  AppsDropdownComponent,
  NotificationDropdownComponent,
  OrganisationDropdownComponent
} from './top-navbar/components';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [
    ShellComponent,
    FooterComponent,
    LeftSidebarComponent,
    TopNavbarComponent,
    // Top Navbar sub components
    OrganisationDropdownComponent,
    NotificationDropdownComponent,
    AppsDropdownComponent
  ]
})
export class ShellModule {}
