import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { IOption } from '@core/models';
import { Observable, debounceTime, distinctUntilChanged, switchMap, of } from 'rxjs';

@Component({
  selector: 'app-form-autocomplete-control',
  templateUrl: './form-autocomplete-control.component.html',
  styleUrls: ['./form-autocomplete-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAutocompleteControlComponent),
      multi: true
    }
  ]
})
export class FormAutocompleteControlComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName!: string;
  @Input() label?: string;
  @Input() help!: string;
  @Input() icon?: string;
  @Input() placeholder: string = '';
  @Input() resultPlaceholder: string = '';
  @Input() disabled: boolean = false;
  @Input() fillTextboxOnSelect: boolean = true;
  @Input() apiUrl!: string;
  @Input() options!: IOption[];
  @Input() debounceTime: number = 300;
  @Output() touch: EventEmitter<void> = new EventEmitter<void>();

  value!: string;
  filteredItems!: IOption[];
  visibleItems!: IOption[];
  showList: boolean = false;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor(private http: HttpClient) {
    // controlDir.valueAccessor = this;
  }

  ngOnInit() {
    this.filteredItems = this.options;
  }

  onInputChange(value: string): void {
    this.filteredItems = [];

    // TODO : add http request
    // if (this.apiUrl && this.apiUrl !== '') {
    //   this.fetchFromApi(value).subscribe(items => {
    //     this.filteredItems = items;
    //   });
    // } else {
    //   this.filteredItems = this.options.filter(item =>
    //     item.toLowerCase().includes(value.toLowerCase())
    //   );
    // }

    this.filteredItems = this.options.filter(item =>
      item.value.toLowerCase().includes(value.toLowerCase())
    );

    this.onChange(value);
    this.showList = value.length > 0;
  }

  // TODO : implement lazy load
  // onLazyLoad(event: any): void {
  //   const { first, rows } = event;

  //   this.visibleItems = this.filteredItems.slice(first, first + rows);
  // }

  selectItem(item: IOption): void {
    this.value = item.value;
    this.filteredItems = this.options;
    this.onChange(item);
    this.onTouched();
  }

  // fetchFromApi(value: string): Observable<string[]> {
  //   return this.http.get<string[]>(`${this.apiUrl}?search=${value}`).pipe(
  //     debounceTime(this.debounceTime),
  //     distinctUntilChanged(),
  //     switchMap(response => of(response))
  //   );
  // }

  writeValue(value: any): void {
    this.value = value;
    // value && this.controlDir.control?.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
