import { Component, OnInit } from '@angular/core';
import { IAppClient } from '@core/models';
import { AuthRedirectionService, AuthService } from '@core/services/auth';

@Component({
  selector: 'app-apps-dropdown',
  templateUrl: './apps-dropdown.component.html',
  styleUrls: ['./apps-dropdown.component.scss']
})
export class AppsDropdownComponent implements OnInit {
  apps?: IAppClient[];

  constructor(private authService: AuthService, private authRedirect: AuthRedirectionService) {}

  async ngOnInit() {
    this.apps = await this.authRedirect.loadApps();
  }

  redirect(app: IAppClient) {
    return this.authRedirect.redirectToApp(app);
  }

  async signOut(): Promise<void> {
    await this.authService.signOut();
  }
}
