import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-review-sdgs',
  templateUrl: './review-sdgs.component.html',
  styleUrls: ['./review-sdgs.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ReviewSdgsComponent implements OnInit {
  parentForm!: FormGroup;

  constructor(private parent: FormGroupDirective) {}

  ngOnInit() {
    this.parentForm = this.parent.form;
    this.parentForm.addControl('sdgs', new FormControl([], Validators.required));
  }
}
