<div class="dialog">
  <div class="dialog-header">
    <i class="hi hi-user-cogs dialog-header-icon"></i>
    <h5 class="dialog-header-text">Invite People for Review</h5>
  </div>
  <div class="dialog-content">
    <div class="reviewer-list w-100 mt-4 mb-2">
      <div class="reviwer-list-item">
        <app-avatar
          class="selected-reviewer"
          [url]="selectedReviewer.avatar"
          [name]="selectedReviewer.name"
          [round]="true"
        ></app-avatar>
        <span>{{selectedReviewer.name}}</span>
      </div>
    </div>
    <form [formGroup]="reviewerForm">
      <div class="d-flex flex-wrap flex-column mt-2 mb-2 roles">
        <div class="d-flex flex-wrap btn-group btn-group-toggle" data-toggle="buttons">
          <app-form-checkbox-group-control
            label="Choose Role"
            [options]="roles"
            [showCheckbox]="false"
            [optionTemplate]="optionTemplate"
            formControlName="assignedRoles"
          >
            <ng-template #optionTemplate let-option>
              <div class="btn btn-outline-secondary btn-role" [class.selected]="option.selected">
                <i class="{{option.icon}}"></i>
                <span class="btn-text d-block"> {{option.label}} </span>
              </div>
            </ng-template>
          </app-form-checkbox-group-control>
        </div>
      </div>
      <div class="sdg-roles">
        <ng-container *ngFor="let role of selectedRoles">
          <div class="d-flex flex-wrap flex-column mt-2 mb-2">
            <app-form-sdgs-control
              label="Assign SDGs for {{role}}"
              [selectedOptions]="selectedSdgs"
              formControlName="assignedSdgs"
            ></app-form-sdgs-control>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="dialog-footer">
    <button type="button" (click)="ref.close(null)" class="btn btn-outline-secondary">
      Cancel
    </button>
    <button type="button" class="btn btn-primary">Add</button>
  </div>
  <!-- For Dev Testing -->
  <!-- <div class="mt-4">
    <b>Form Status:</b>
    {{ reviewerForm.status }} {{ reviewerForm.touched ? "touched" : "untouched" }} {{
    reviewerForm.dirty ? "dirty" : "pristine" }}
    <br />
    <b>Reviewer: </b>
    {{ reviewerForm.value | json }}
  </div> -->
</div>
