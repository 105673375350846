import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {
  @Input() isCollapsed = false;
  @Output() toggleEvent = new EventEmitter<boolean>();

  private MIN_WIDTH_SIZE = 576;
  private MAX_WIDTH_SIZE = 991.98;

  get currentNavigationName() {
    const route = this.route.snapshot.firstChild;

    let name;
    if (route?.outlet === 'primary') {
      name = route.title;
    }

    return name;
  }

  get currentNavigationButton(): { name: string; route: string } {
    const route = this.route.snapshot.firstChild;

    let button;
    if (route?.outlet === 'primary') {
      button = route.data['topNavButton'];
    }

    return button;
  }

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit() {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const width = event.target.innerWidth;
    if (width && width >= this.MIN_WIDTH_SIZE && width <= this.MAX_WIDTH_SIZE) {
      this.isCollapsed = true;
      this.toggleEvent.emit(this.isCollapsed);
    }
  }

  onToggle() {
    this.isCollapsed = !this.isCollapsed;
    this.toggleEvent.emit(this.isCollapsed);
  }
}
