<div class="card my-reviews">
  <div class="card-header">
    <i class="hi hi-template-1"></i>
    MY REVIEWS
  </div>
  <div class="card-body">
    <div class="d-flex flex-wrap whitespace mb-5">
      <div class="whitespace-col-1 flex-grow mb-3 mb-lg-0 order-sm-2 order-md-1">
        <div class="whitespace-text">
          <h1 class="whitespace-title h5">
            You can view all reviews that you have been assigned to contribute, review or moderate.
          </h1>
        </div>
      </div>
      <div class="whitespace-col-2 flex-grow order-sm-1 order-md-2">
        <img
          alt="my-review-whitespace"
          src="assets/images/reviewer/my-reviews.svg"
          class="whitespace-img"
        />
      </div>
    </div>
    <div class="d-flex flex-column">
      <h1 class="h5">
        <span class="me-2">My Reviews</span>
        <a href="/reviewer/new-review" class="btn btn-primary">
          <i class="hi hi-add"></i>
          <span>CREATE REVIEW</span>
        </a>
      </h1>
      <app-datatable
        *ngIf="columns && columns.length"
        name="review"
        [search]="searchFilter"
        [data]="reviews"
        [bodyTemplate]="customBodyTemplate"
        [columns]="columns"
        [loading]="loading"
        [rowCount]="5"
      >
        <ng-template #customBodyTemplate let-rowData>
          <tr>
            <td>
              <span
                *ngIf="(rowData.name | truncate:43)[1]"
                [pTooltip]="rowData.name"
                tooltipPosition="bottom"
                class="d-inline-block"
              >
                {{(rowData.name | truncate:43)[0]}}
              </span>
              <span *ngIf="rowData.name.length < 43" class="d-inline-block">
                {{rowData.name}}
              </span>
            </td>
            <td>
              <i *ngIf="rowData.role.toLowerCase() === 'moderator'" class="hi hi-user-cogs"></i>
              <i *ngIf="rowData.role.toLowerCase() === 'reviewer'" class="hi hi-user-star"></i>
              <i *ngIf="rowData.role.toLowerCase() === 'contributor'" class="hi hi-user-up"></i>
              {{rowData.role}}
            </td>
            <td>
              <i
                *ngIf="rowData.status.toLowerCase() === 'closed'"
                class="hi hi-c-check hi-status-complete"
              ></i>
              <i
                *ngIf="rowData.status.toLowerCase() === 'in progress'"
                class="hi hi-c-repeat hi-status-progress"
              ></i>
              <i
                *ngIf="rowData.status.toLowerCase() === 'draft'"
                class="hi hi-c-clock hi-status-draft"
              ></i>
              {{rowData.status}}
            </td>
            <td>
              <ng-container *ngFor="let sdg of rowData.sdgs?.slice(0, 3)">
                <span
                  class="hi-sdg-container"
                  [pTooltip]="getSdgDescription(sdg.id)"
                  tooltipPosition="bottom"
                >
                  <i
                    *ngIf="rowData.status.toLowerCase() === 'closed'"
                    class="hi hi-c-check hi-sdg-status hi-status-complete"
                  ></i>
                  <i
                    *ngIf="rowData.status.toLowerCase() === 'in progress'"
                    class="hi hi-c-repeat hi-sdg-status hi-status-progress"
                  ></i>
                  <i
                    *ngIf="rowData.status.toLowerCase() === 'draft'"
                    class="hi hi-c-clock hi-sdg-status hi-status-draft"
                  ></i>
                  <i class="hi-sdg hi-sdg-{{sdg.id}}"></i>
                </span>
              </ng-container>
              <ng-container *ngIf="rowData.sdgs?.length > 3">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-sdg"
                  pButton
                  (click)="onOpenDialog(rowData)"
                >
                  +{{rowData.sdgs.slice(3).length}}
                </button>
              </ng-container>
            </td>
            <td>
              <span class="d-block">{{rowData.creator.name}}</span>
              <span class="d-block text-muted small">{{rowData.creator.jobTitle}}</span>
            </td>
          </tr>
        </ng-template>
      </app-datatable>
    </div>
  </div>
</div>
