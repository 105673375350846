import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IFilter, IOption, ITableColumn, ITableSearch, sdgs } from '@core/models';
import { IReview, IReviewSDG } from '@core/models/reviewer.model';
import { ReviewerService } from '@core/services/reviewer';
import { DialogService } from 'primeng/dynamicdialog';
import { AssignedSdgsComponent } from './components';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.scss']
})
export class MyReviewsComponent implements OnInit {
  @ViewChild('customBodyTemplate') customBodyTemplate?: TemplateRef<any>;

  reviews: IReview[] = [];
  loading: boolean = true;

  searchFilter: ITableSearch = {
    field: 'name',
    placeholder: 'Search for review...',
    filters: []
  };

  columns: ITableColumn[] = [
    {
      name: 'Review Name',
      width: 340,
      field: 'name',
      sortable: true
    },
    {
      name: 'Role',
      field: 'role',
      sortable: true
    },
    {
      name: 'Status',
      field: 'status',
      sortable: true
    },
    {
      name: 'Assigned SDGs',
      field: 'sdg.name',
      sortable: false
    },
    {
      name: 'Review Creator',
      width: 250,
      field: 'creator.name',
      sortable: true
    }
  ];

  constructor(private dialogService: DialogService, private reviewer: ReviewerService) {}

  ngOnInit() {
    this.reviewer.getMyReviews().subscribe(reviews => {
      this.reviews = reviews;
      this.loading = false;

      // order of filter function here is important
      this.getSDGFilter(reviews);
      this.getCreatorFilter(reviews);
    });
  }

  getCreatorFilter(reviews: IReview[]) {
    const distinctCreators = Array.from(new Set(reviews.flatMap(review => review.creator)));
    const creatorOptions: IOption[] = distinctCreators.map(creator => ({
      label: creator.name,
      value: creator.id.toString(),
      selected: false
    }));

    if (creatorOptions) {
      this.searchFilter.filters.push({
        name: 'Creators',
        field: 'creator.name',
        options: creatorOptions
      });
    }
  }

  getSDGFilter(reviews: IReview[]) {
    const distinctSdgs: IReviewSDG[] = reviews
      .flatMap(r => r.sdgs)
      .filter((sdg): sdg is IReviewSDG => sdg !== undefined)
      .reduce((sdgs: IReviewSDG[], sdg: IReviewSDG) => {
        const existingSdg = sdgs.find(s => s.id === sdg.id);
        if (!existingSdg) {
          sdgs.push(sdg);
        }
        return sdgs;
      }, []);

    const sdgOptions: IOption[] = distinctSdgs.map(sdg => ({
      label: sdg.name,
      value: sdg.id.toString(),
      selected: false
    }));

    if (sdgOptions) {
      this.searchFilter.filters.push({
        name: 'SDGs',
        field: 'sdg.name',
        options: sdgOptions
      });
    }
  }

  getSdgDescription(index: any): string {
    return `${sdgs[index].alias} - ${sdgs[index].name}`;
  }

  onOpenDialog(review: IReview): void {
    const ref = this.dialogService.open(AssignedSdgsComponent, {
      closable: false,
      contentStyle: { overflow: 'hidden' },
      width: '32%',
      showHeader: false,
      data: {
        review: review
      }
    });
  }
}
