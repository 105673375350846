<div class="dropdown nav-orgs">
  <a
    class="nav-link active dropdown-toggle"
    href="#"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    pTooltip="{{ selectedOrganisation?.name || '' | translate }}"
    tooltipPosition="bottom"
  >
    <img
      appImageFallback
      [alt]="selectedOrganisation?.name"
      [src]="selectedOrganisation?.logoUrl || defaultOrgImage"
      class="nav-link-img"
    />
    <span class="nav-link-text ms-2 text-wrap">{{selectedOrganisation?.name}}</span>
  </a>
  <ul *ngIf="organisations" class="dropdown-menu dropdown-menu-end">
    <li class="dropdown-item">
      <a href="#" class="dropdown-item-link dropdown-item-org">
        <div class="dropdown-container-img">
          <img
            appImageFallback
            alt="{{selectedOrganisation?.name}}"
            [src]="selectedOrganisation?.logoUrl || defaultOrgImage"
            class="dropdown-item-img"
          />
        </div>
        <div class="dropdown-container-text">
          <span class="dropdown-item-text text-wrap">{{selectedOrganisation?.name}}</span>
        </div>
      </a>
    </li>
    <li class="dropdown-item dropdown-item-divider"><hr class="dropdown-divider" /></li>
    <li class="dropdown-item-label">
      <span class="text-muted">ORGANISATION SETTINGS</span>
    </li>
    <!-- Organisation List of features -->
    <ng-container *ngIf="selectedOrganisation">
      <li class="dropdown-item" *ngFor="let feature of selectedOrganisation.features">
        <a href="{{feature.route}}" class="dropdown-item-link">
          <i class="{{ feature.icon }}"></i>
          <span class="dropdown-item-text">{{feature.name}}</span>
        </a>
      </li>
    </ng-container>
    <li class="dropdown-item dropdown-item-divider"><hr class="dropdown-divider" /></li>
    <!-- Organisation List -->
    <ng-container *ngFor="let org of organisations">
      <li class="dropdown-item" *ngIf="!selectedOrganisation || org.id !== selectedOrganisation.id">
        <a href="#" class="dropdown-item-link dropdown-item-org" (click)="onSelectedChange(org)">
          <div class="dropdown-container-img">
            <img
              appImageFallback
              alt="{{org.name}}"
              [src]="org.logoUrl || defaultOrgImage"
              class="dropdown-item-img"
            />
          </div>
          <div class="dropdown-container-text">
            <span class="dropdown-item-text text-wrap">{{org.name}}</span>
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
