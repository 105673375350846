<div class="d-flex flex-wrap whitespace mb-5">
  <div class="whitespace-col-1 flex-grow mb-3 mb-lg-0 order-sm-2 order-md-1">
    <div class="whitespace-text">
      <h1 class="whitespace-title h5">Select roles for participants</h1>
      <p class="whitespace-desc">
        Use the search field below to invite people or groups you would like to participate in the
        review and define their roles as Contributor, Reviewer and Moderator.
      </p>
    </div>
  </div>
  <div class="whitespace-col-2 flex-grow order-sm-1 order-md-2">
    <img alt="step-4" src="assets/images/reviewer/illustration-step-3.svg" class="whitespace-img" />
  </div>
</div>
