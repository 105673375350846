<div class="d-flex flex-wrap whitespace mb-5">
  <div class="whitespace-col-1 flex-grow mb-3 mb-lg-0 order-sm-2 order-md-1">
    <div class="whitespace-text">
      <h1 class="whitespace-title h5">Predefined templates</h1>
      <p class="whitespace-desc">
        You can select one of our predefined templates for REF and THE Impact Reporting or one of
        your already created templates.
      </p>
    </div>
  </div>
  <div class="whitespace-col-2 flex-grow order-sm-1 order-md-2">
    <img alt="step-4" src="assets/images/reviewer/illustration-step-2.svg" class="whitespace-img" />
  </div>
</div>
<div class="d-flex flex-wrap justify-content-center mt-2 mb-4">
  <div class="d-flex flex-wrap btn-group btn-group-toggle btn-group-template" data-toggle="buttons">
    <app-form-radio-control
      formControlName="templateId"
      [optionTemplate]="optionTemplate"
      [showRadio]="false"
      [options]="templates"
    >
      <ng-template #optionTemplate let-option>
        <div class="btn btn-outline-secondary btn-template" [class.selected]="option.selected">
          <i [class]="option.icon"></i>
          <span class="btn-text d-block"> {{option.label}} </span>
        </div>
      </ng-template>
    </app-form-radio-control>
  </div>
</div>
<div class="d-flex justify-content-center mb-5">
  <button role="button" class="btn btn-outline-primary">
    <i class="hi hi-add"></i>
    <span>CREATE CUSTOM TEMPLATE</span>
  </button>
</div>
<div class="d-flex mb-4">
  <h1 class="h5">Basic review information</h1>
</div>
<div class="row">
  <div class="col-lg-5 col-xs-12 col-sm-6">
    <app-form-text-control
      icon="hi hi-file"
      label="Review Name"
      help="Lorem ipsum"
      formControlName="name"
    ></app-form-text-control>
  </div>
  <div class="col-lg-5 col-xs-12 col-sm-6">
    <app-form-date-range-control
      label="Review Period"
      formControlName="period"
    ></app-form-date-range-control>
  </div>
</div>
