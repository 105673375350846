<div class="d-flex flex-wrap whitespace mb-5">
  <div class="whitespace-col-1 flex-grow mb-3 mb-lg-0 order-sm-2 order-md-1">
    <div class="whitespace-text">
      <h1 class="whitespace-title h5">Double-check your review</h1>
      <p class="whitespace-desc">
        Your review is almost ready to be created. Spend a minute to check the summary on this step
        and make sure everything is correct.
      </p>
    </div>
  </div>
  <div class="whitespace-col-2 flex-grow order-sm-1 order-md-2">
    <img alt="step-4" src="assets/images/reviewer/illustration-step-4.svg" class="whitespace-img" />
  </div>
</div>
<!-- <div class="d-flex flex-wrap mb-4">
  <div class="col-6">
    <h1 class="h5">
      <span class="me-2">Review Name</span>
      <i class="hi hi-edit"></i>
    </h1>
    <p>dfafadsfdasfdasfdafdasf</p>
  </div>
  <div class="col-6">
    <h1 class="h5">
      <span class="me-2">Review Period</span>
      <i class="hi hi-edit"></i>
    </h1>
  </div>
</div>
<div class="d-flex flex-wrap mb-4">
  <div class="col-6">
    <h1 class="h5">
      <span class="me-2">Used Template</span>
      <i class="hi hi-edit"></i>
    </h1>
  </div>
  <div class="col-6">
    <h1 class="h5">
      <span class="me-2">Selected SDGs</span>
      <i class="hi hi-edit"></i>
    </h1>
  </div>
</div> -->
