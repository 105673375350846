import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { IReviewTemplate } from '@core/models';
import {
  ReviewPublishComponent,
  ReviewRolesComponent,
  ReviewSdgsComponent,
  ReviewTemplatesComponent
} from './components';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth';

@Component({
  selector: 'app-new-review',
  templateUrl: './new-review.component.html',
  styleUrls: ['./new-review.component.scss']
})
export class NewReviewComponent implements OnInit, AfterViewInit, AfterContentChecked {
  activeTabIndex = 0;
  form: FormGroup;
  // TODO : make tabs dynamic based on the select template
  tabs: IReviewTemplate[] = [
    {
      id: 1,
      icon: 'template-1',
      name: 'CHOOSE A TEMPLATE',
      description: 'Select a predefined template for the review',
      content: ReviewTemplatesComponent
    },
    {
      id: 2,
      icon: 'star',
      name: 'SELECT SDGs',
      description: 'Choose which SDGs you want to collect data for',
      content: ReviewSdgsComponent
    },
    {
      id: 3,
      icon: 'users',
      name: 'ASSIGN ROLES',
      description: 'Add contributors, reviewers and moderators',
      content: ReviewRolesComponent
    },
    {
      id: 4,
      icon: 'search-plus',
      name: 'PREVIEW',
      description: 'Double-check and publish your review',
      content: ReviewPublishComponent
    }
  ];

  get isFirst() {
    return this.activeTabIndex === 0;
  }

  get isLast() {
    return this.activeTabIndex === this.tabs.length - 1;
  }

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, private auth: AuthService) {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    // TODO : need to create organisation service? maybe to know which organisation a user is currently selected
    const ownerId = this.auth.authUserInfo?.id;
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  onSubmit() {}

  onPrev(count: number) {
    this.activeTabIndex = count - 1;
  }

  onNext(count: number) {
    this.activeTabIndex = this.isLast ? count : count + 1;
  }
}
