<nav class="{{styleClass}} {{isCollapsed ? 'compacted' : 'expanded' }} pt-3 pb-5">
  <div class="sidebar-close">
    <a type="button" (click)="onClose()">
      <i class="hi hi-close"></i>
    </a>
  </div>
  <div class="brand text-center px-3">
    <a href="/">
      <img alt="ImpactTracker-logo" class="img-fluid" src="/assets/brand/logo-full-reversed.svg" />
      <img alt="ImpactTracker-logo" class="img-fluid" src="/assets/brand/logo-reversed.svg" />
    </a>
  </div>
  <div class="profile">
    <div class="profile-img">
      <a href="{{profileUrl}}">
        <app-avatar class="profile-avatar" [avatar]="avatar">
          <div indicator class="profile-avatar-status"></div>
        </app-avatar>
      </a>
    </div>
    <div class="profile-desc p-3">
      <span class="d-inline-block">{{title}}</span>
      <span class="d-inline-block">{{fullName}}</span>
      <span class="d-block profile-jobtitle">{{jobTitle}}</span>
    </div>
  </div>
  <div class="w-100 divider-1"></div>
  <div class="w-100 divider-2"></div>
  <ul class="nav nav-menu flex-column mt-1">
    <ng-container *ngFor="let menu of menus">
      <li
        class="nav-item {{menu.styleClass}}"
        routerLinkActive="{{!menu.route && menu.subMenus ? null : 'active'}}"
        [pTooltip]="menu.i18n | translate"
        tooltipStyleClass="tooltip {{ isCollapsed && menu.route && !menu.subMenus ? 'd-inline-block' : 'd-none'}}"
      >
        <ng-container *ngIf="menu.route && !menu.subMenus">
          <a [routerLink]="[menu.route]" class="nav-link">
            <i *ngIf="menu.icon" class="{{menu.icon}}"></i>
            <span class="nav-link-text">{{ menu.i18n | translate }}</span>
            <div *ngIf="menu.hasCounter" class="nav-link-counter">
              <span class="nav-link-counter-text">3</span>
            </div>
          </a>
        </ng-container>
        <ng-container *ngIf="!menu.route && menu.subMenus">
          <ng-container *ngIf="!isCollapsed">
            <a
              class="nav-link"
              href="#"
              role="button"
              data-bs-toggle="collapse"
              attr.data-bs-target="#{{menu.name}}"
              aria-expanded="false"
            >
              <i *ngIf="menu.icon" class="{{menu.icon}}"></i>
              <span class="nav-link-text">{{ menu.i18n | translate }}</span>
              <span class="nav-link-caret"></span>
              <div *ngIf="menu.hasCounter" class="nav-link-counter">
                <span class="nav-link-counter-text">3</span>
              </div>
            </a>
            <div class="collapse" id="{{menu.name}}">
              <ul class="nav nav-menu nav-submenu">
                <ng-container *ngFor="let subMenu of menu.subMenus">
                  <li
                    routerLinkActive="active"
                    class="nav-item nav-submenu-item {{menu.styleClass}}"
                    [pTooltip]="subMenu.i18n | translate"
                    tooltipStyleClass="tooltip {{ isCollapsed ? 'd-inline-block' : 'd-none'}}"
                  >
                    <a [routerLink]="[subMenu.route]" class="nav-link nav-submenu-link">
                      <i *ngIf="subMenu.icon" class="{{subMenu.icon}}"></i>
                      <span class="nav-link-text nav-submenu-text"
                        >{{ subMenu.i18n | translate }}</span
                      >
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
          <!-- nav with sub-menus -->
          <ng-container *ngIf="isCollapsed">
            <div class="dropend">
              <a
                href="#"
                role="button"
                class="nav-link"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i *ngIf="menu.icon" class="{{menu.icon}}"></i>
                <span class="nav-link-text">{{ menu.i18n | translate }}</span>
                <span class="dropdown-toggle"></span>
                <div class="nav-link-counter"><span class="nav-link-counter-text">3</span></div>
              </a>
              <ul class="dropdown-menu dropdown-submenu dark">
                <ng-container *ngFor="let subMenu of menu.subMenus">
                  <li class="dropdown-item dropdown-submenu-item">
                    <a
                      class="dropdown-item-link"
                      routerLinkActive="active"
                      [routerLink]="[subMenu.route]"
                    >
                      <i *ngIf="subMenu.icon" class="{{subMenu.icon}}"></i>
                      <span class="dropdown-item-text">{{ subMenu.i18n | translate }}</span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</nav>
