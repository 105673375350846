<ng-container
  appFullContent
  bodyClass="d-flex flex-column h-100 bg-gradient"
  rootClass="d-flex flex-column h-100"
>
  <app-top-navbar [isCollapsed]="isCollapsed" (toggleEvent)="onToggle($event)"></app-top-navbar>
  <div class="container-fluid {{ isCollapsed ? 'compacted' : 'expanded' }}">
    <app-left-sidebar
      (closeEvent)="onClose($event)"
      [isCollapsed]="isCollapsed"
      styleClass="sidebar dark"
    ></app-left-sidebar>
    <main>
      <div class="container-lg">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
  <app-footer class="mt-auto"></app-footer>
</ng-container>
