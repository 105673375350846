import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { ITableColumn } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-review-publish',
  templateUrl: './review-publish.component.html',
  styleUrls: ['./review-publish.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ReviewPublishComponent implements OnInit {
  parentForm!: FormGroup;

  constructor(private parent: FormGroupDirective) {}

  ngOnInit(): void {
    this.parentForm = this.parent.form;
  }
}
