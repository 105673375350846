<div class="form-group">
  <label *ngIf="label" [for]="formControlName" class="form-label">
    <span>{{label}}</span>
    <i *ngIf="help" class="hi hi-c-question-outline" [pTooltip]="help"></i>
  </label>
  <div class="input-group">
    <span class="input-group-text">
      <i class="{{icon}}"></i>
    </span>
    <input
      class="form-control"
      (input)="onChange($any($event.target).value)"
      (blur)="onTouched(); touch.emit();"
      pInputText
      type="text"
      placeholder="{{placeholder}}"
      [attr.aria-label]="placeholder"
      [disabled]="disabled"
    />
  </div>
  <!-- TODO : add error messages here -->
</div>
