import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number, truncate: string = '...'): [string, boolean] {
    if (!value) {
      return ['', false];
    }

    if (value.length <= maxLength) {
      return [value, false];
    }

    const lastSpaceIndex = value.lastIndexOf(' ', maxLength);

    if (lastSpaceIndex === -1) {
      return [`${value.slice(0, maxLength)}${truncate}`, true];
    }

    const truncatedValue = value.slice(0, lastSpaceIndex);

    if (truncatedValue.length <= maxLength - 3) {
      return [`${truncatedValue}${truncate}`, true];
    }

    return [`${truncatedValue.slice(0, maxLength - 3)}${truncate}`, true];
  }
}
