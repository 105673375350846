import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@layouts/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'dashboard',
      title: 'Dashboard',
      data: { topNavButton: { name: 'CREATE PROJECT', route: '#' } },
      loadChildren: () =>
        import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'reviewer',
      title: 'ImpactReviewer',
      data: { topNavButton: { name: 'CREATE REVIEW', route: '/new-review' } },
      loadChildren: () => import('./modules/reviewer/reviewer.module').then(m => m.ReviewerModule)
    }
  ]),
  {
    path: 'extension',
    title: 'ImpactTracker Extension',
    loadChildren: () => import('./modules/extension/extension.module').then(m => m.ExtensionModule)
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
