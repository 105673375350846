import { Type } from '@angular/core';
export interface IReview {
  id?: string;
  name: string;
  role: string;
  status: string;
  sdgs?: IReviewSDG[];
  creator: IReviewCreator;
}

export interface IReviewSDG {
  id: string | number;
  name: string;
  status: string;
}

export interface IReviewCreator {
  id: string;
  jobTitle: string;
  name: string;
}

export interface IReviewTemplate {
  id: number;
  icon: string;
  name: string;
  description: string;
  content: Type<any>;
}

export interface IReviewer {
  id: number;
  type: ReviewerType;
  name: string;
  title: string;
  avatar?: string;
  groups?: string[];
}

export enum ReviewerType {
  User = 1,
  Group = 2
}

export enum ReviewerRole {
  Moderator = 1,
  Reviewer = 2,
  Contributor = 3
}

export interface IReviewFormGroup {
  status: string;
  templateId: string;
  name: string;
  periodStartDate: Date;
  periodEndDate: Date;
  sdgs: string[];
  participants: IReviewParticipant[];
}

export interface IReviewParticipant {
  account: {
    id: string;
    name: string;
    designation: string;
    groups: string[];
  };
  role: string;
  sdgs: number[];
}
