<div class="d-flex flex-wrap whitespace mb-5">
  <div class="whitespace-col-1 flex-grow mb-3 mb-lg-0 order-sm-2 order-md-1">
    <div class="whitespace-text">
      <h1 class="whitespace-title h5">Select roles for participants</h1>
      <p class="whitespace-desc">
        Use the search field below to invite people or groups you would like to participate in the
        review and define their roles as Contributor, Reviewer and Moderator.
      </p>
    </div>
  </div>
  <div class="whitespace-col-2 flex-grow order-sm-1 order-md-2">
    <img alt="step-4" src="assets/images/reviewer/illustration-step-3.svg" class="whitespace-img" />
  </div>
</div>
<div class="row">
  <div class="col-lg-10 col-xs-12 col-sm-12">
    <h1 class="h6 fw-bold mb-2">Suggested people and groups to invite</h1>
    <ul class="list-group d-flex flex-wrap flex-row">
      <li *ngFor="let reviewer of suggestedReviewers" class="list-group-item">
        <app-avatar
          class="reviewer-avatar"
          [url]="reviewer.avatar"
          [name]="reviewer.name"
          [round]="true"
          (click)="onOpenDialog(reviewer)"
        >
        </app-avatar>
        <ng-container [ngSwitch]="(reviewer.name | truncate: 20)[1]">
          <span *ngSwitchCase="true" [pTooltip]="reviewer.name">
            {{ (reviewer.name | truncate:20)[0] }}
          </span>
          <p *ngSwitchCase="false">{{(reviewer.name | truncate:20)[0]}}</p>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
<div class="row mt-3">
  <div class="col-lg-5 col-xs-12 col-sm-6">
    <app-form-autocomplete-control
      [options]="options"
      icon="hi hi-user-add"
      label="Invite people for review"
      placeholder="Search or invite people and groups..."
      resultPlaceholder="People and groups"
    ></app-form-autocomplete-control>
  </div>
</div>
