import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyReviewsComponent } from './my-reviews/my-reviews.component';
import { NewReviewComponent } from './new-review/new-review.component';

const routes: Routes = [
  { path: 'my-reviews', component: MyReviewsComponent, title: 'My Reviews' },
  { path: 'new-review', component: NewReviewComponent, title: 'New Review' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ReviewerRoutingModule {}
