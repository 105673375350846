import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { IAvatar, IErrorEvent } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  isInitial = false;
  hasError = false;

  initial: string | undefined;

  @Input() avatar: IAvatar | undefined;
  @Input() name: string | undefined;
  @Input() url: string | undefined;
  @Input() round: boolean = false;
  @Input() srcImg: string | undefined;

  @HostBinding('class') classes: string[] = [];

  constructor() {}

  ngOnInit() {
    let name = this.avatar?.name;

    if (!name && this.name) {
      name = this.name;
    }

    this.initial = this.getInitial(name);
    this.classes.push(`random-color-${Math.floor(Math.random() * 6) + 1}`);

    if (this.avatar?.round || this.round) {
      this.classes.push('round');
    }

    this.isInitial = !this.avatar?.url || !this.url;
  }

  getInitial(name: string | undefined) {
    if (!name) {
      throw new Error('Avatar name is undefined. Defined either pass the Avatar object or name');
    }

    // split into individual names
    const names = name.trim().split(/\s+/);

    if (names.length === 1) {
      // if only one name, use the first initial
      return names[0].charAt(0).toUpperCase();
    }

    let firstName = names[0];
    let lastName = names[names.length - 1];

    if (names.length === 3) {
      lastName = names[names.length - 1];
    } else if (names.length > 3) {
      lastName = names[2];
    }

    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  onError(event: any) {
    this.hasError = event;
  }
}
