import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { EditorModule } from 'primeng/editor';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { CalendarModule } from 'primeng/calendar';
import { DragDropModule } from 'primeng/dragdrop';
import { TabViewModule } from 'primeng/tabview';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  imports: [
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    PaginatorModule,
    TableModule,
    TabViewModule,
    TooltipModule,
    ScrollPanelModule,
    InputSwitchModule,
    EditorModule,
    VirtualScrollerModule
  ],
  exports: [
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    PaginatorModule,
    TableModule,
    TabViewModule,
    TooltipModule,
    ScrollPanelModule,
    InputSwitchModule,
    EditorModule,
    VirtualScrollerModule
  ],
  providers: [DialogService]
})
export class PrimengModule {}
