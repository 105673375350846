<div class="header">
  <h1 class="h5">{{review.name}} - Assigned SDGs</h1>
</div>
<div class="d-flex flex-wrap">
  <ng-container *ngFor="let sdg of review.sdgs">
    <span class="hi-sdg-container" [pTooltip]="getSdgDescription(sdg.id)" tooltipPosition="bottom">
      <i
        *ngIf="sdg.status.toLowerCase() === 'closed'"
        class="hi hi-c-check hi-sdg-status hi-status-complete"
      ></i>
      <i
        *ngIf="sdg.status.toLowerCase() === 'in progress'"
        class="hi hi-c-repeat hi-sdg-status hi-status-progress"
      ></i>
      <i
        *ngIf="sdg.status.toLowerCase() === 'draft'"
        class="hi hi-c-clock hi-sdg-status hi-status-draft"
      ></i>
      <i class="hi-sdg hi-sdg-{{sdg.id}}"></i>
    </span>
  </ng-container>
</div>
<div class="footer">
  <button type="button" (click)="ref.close(null)" class="btn btn-primary">Close</button>
</div>
