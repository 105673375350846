export interface SDG {
  id: number;
  name: string;
  alias: string;
}

export const sdgs: Record<number, SDG> = {
  1: {
    id: 1,
    name: 'No Poverty',
    alias: 'SDG 1'
  },
  2: {
    id: 2,
    name: 'Zero Hunger',
    alias: 'SDG 2'
  },
  3: {
    id: 3,
    name: 'Good Health and Well-being',
    alias: 'SDG 3'
  },
  4: {
    id: 4,
    name: 'Quality Education',
    alias: 'SDG 4'
  },
  5: {
    id: 5,
    name: 'Gender Equality',
    alias: 'SDG 5'
  },
  6: {
    id: 6,
    name: 'Clean Water and Sanitation',
    alias: 'SDG 6'
  },
  7: {
    id: 7,
    name: 'Affordable and Clean Energy',
    alias: 'SDG 7'
  },
  8: {
    id: 8,
    name: 'Decent Work and Economic Growth',
    alias: 'SDG 8'
  },
  9: {
    id: 9,
    name: 'Industry, Innovation and Infrastructure',
    alias: 'SDG 9'
  },
  10: {
    id: 10,
    name: 'Reduced Inequalities',
    alias: 'SDG 10'
  },
  11: {
    id: 11,
    name: 'Sustainable Cities and Communities',
    alias: 'SDG 11'
  },
  12: {
    id: 12,
    name: 'Responsible Consumption and Production',
    alias: 'SDG 12'
  },
  13: {
    id: 13,
    name: 'Climate Action',
    alias: 'SDG 13'
  },
  14: {
    id: 14,
    name: 'Life Below Water',
    alias: 'SDG 14'
  },
  15: {
    id: 15,
    name: 'Life On Land',
    alias: 'SDG 15'
  },
  16: {
    id: 16,
    name: 'Peace, Justice and Strong Institutions',
    alias: 'SDG 16'
  },
  17: {
    id: 17,
    name: 'Partnerships for the Goals',
    alias: 'SDG 17'
  }
};
