import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IOption } from '@core/models';

@Component({
  selector: 'app-form-sdgs-control',
  templateUrl: './form-sdgs-control.component.html',
  styleUrls: ['./form-sdgs-control.component.scss']
})
export class FormSdgsControlComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName!: string;
  @Input() label?: string;
  @Input() help!: string;
  @Input() disabled: boolean = false;
  @Input() selectedOptions: string[] = [];
  @Output() touch: EventEmitter<void> = new EventEmitter<void>();

  options: IOption[] = [
    { label: 'SDG 1 - No Poverty', value: '1', icon: 'hi-sdg hi-sdg-1', selected: false },
    { label: 'SDG 2 - Zero Hunger', value: '2', icon: 'hi-sdg hi-sdg-2', selected: false },
    {
      label: 'SDG 3 - Good Health and Well-being',
      value: '3',
      icon: 'hi-sdg hi-sdg-3',
      selected: false
    },
    { label: 'SDG 4 - Quality Education', value: '4', icon: 'hi-sdg hi-sdg-4', selected: false },
    { label: 'SDG 5 - Gender Equality', value: '5', icon: 'hi-sdg hi-sdg-5', selected: false },
    {
      label: 'SDG 6 - Clean Water and Sanitation',
      value: '6',
      icon: 'hi-sdg hi-sdg-6',
      selected: false
    },
    {
      label: 'SDG 7 - Affordable and Clean Energy',
      value: '7',
      icon: 'hi-sdg hi-sdg-7',
      selected: false
    },
    {
      label: 'SDG 8 - Decent Work and Economic Growth',
      value: '8',
      icon: 'hi-sdg hi-sdg-8',
      selected: false
    },
    {
      label: 'SDG 9 - Industry, Innovation and Infrastructure',
      value: '9',
      icon: 'hi-sdg hi-sdg-9',
      selected: false
    },
    {
      label: 'SDG 10 - Reduced Inequalities',
      value: '10',
      icon: 'hi-sdg hi-sdg-10',
      selected: false
    },
    {
      label: 'SDG 11 - Sustainable Cities and Communities',
      value: '11',
      icon: 'hi-sdg hi-sdg-11',
      selected: false
    },
    {
      label: 'SDG 12 - Responsible Consumption and Production',
      value: '12',
      icon: 'hi-sdg hi-sdg-12',
      selected: false
    },
    { label: 'SDG 13 - Climate Action', value: '13', icon: 'hi-sdg hi-sdg-13', selected: false },
    { label: 'SDG 14 - Life Below Water', value: '14', icon: 'hi-sdg hi-sdg-14', selected: false },
    { label: 'SDG 15 - Life On Land', value: '15', icon: 'hi-sdg hi-sdg-15', selected: false },
    {
      label: 'SDG 16 - Peace, Justice and Strong Institutions',
      value: '16',
      icon: 'hi-sdg hi-sdg-16',
      selected: false
    },
    {
      label: 'SDG 17 - Partnerships for the Goals',
      value: '17',
      icon: 'hi-sdg hi-sdg-17',
      selected: false
    }
  ];

  values: string[] = [];

  onChange: (value: string[]) => void = () => {};
  onTouched: () => void = () => {};

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit() {
    if (this.selectedOptions && this.selectedOptions.length > 0) {
      this.options = this.options.filter(checkbox => this.selectedOptions.includes(checkbox.value));
    }
  }

  onOptionSelect() {
    this.values = this.options
      .filter(checkbox => checkbox.selected)
      .map(checkbox => checkbox.value);

    this.onChange(this.values);
    this.onTouched();
  }

  writeValue(value: any): void {
    value && this.controlDir.control?.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
