<div class="dropdown">
  <a
    class="nav-link notification"
    href="#"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    pTooltip="{{ 'LAYOUTS.SHELL_TOP_NAVBAR.NOTIFICATIONS' | translate }}"
    tooltipPosition="bottom"
  >
    <i class="hi hi-bell"></i>
    <div class="notification-counter">
      <span class="notification-counter-text">3</span>
    </div>
  </a>
  <ul class="dropdown-menu dropdown-menu-end bg-light">
    <li class="dropdown-item py-4">
      <a href="#">Text 1</a>
    </li>
    <li class="dropdown-item py-4">
      <a href="#">Text 2</a>
    </li>
    <li class="dropdown-item py-4">
      <a href="#">Text 3</a>
    </li>
  </ul>
</div>
