<div class="dropdown">
  <a
    class="nav-link"
    href="#"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    pTooltip="{{ 'LAYOUTS.SHELL_TOP_NAVBAR.APPS' | translate }}"
    tooltipPosition="bottom"
  >
    <i class="hi hi-grid-3x3"></i>
  </a>
  <ul *ngIf="apps" class="dropdown-menu dropdown-menu-end bg-light">
    <ng-container *ngFor="let app of apps">
      <!-- TODO: refactor this -->
      <li *ngIf="!app.client_name.includes('ImpactTracker')" class="dropdown-item py-4">
        <a href="{{app.redirect_uri}}">
          <img alt="{{app.client_name}}" src="{{app.client_logo}}" class="img-fluid app-logo" />
        </a>
      </li>
    </ng-container>
    <li class="dropdown-item text-center">
      <button type="button" class="btn btn-link btn-signout" (click)="signOut()">Sign Out</button>
    </li>
  </ul>
</div>
