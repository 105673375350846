<div class="form-group">
  <label *ngIf="label" [for]="formControlName" class="form-label">
    <span>{{label}}</span>
    <i *ngIf="help" class="hi hi-c-question-outline" [pTooltip]="help"></i>
  </label>
  <div *ngFor="let option of options" class="form-check form-check-inline">
    <input
      id="{{formControlName}}-{{option.value}}"
      class="form-check-input"
      type="radio"
      [class.form-check-input-hide]="!showRadio"
      [value]="option.value"
      [(ngModel)]="value"
      (change)="onOptionSelect(option)"
    />
    <label for="{{formControlName}}-{{option.value}}" class="form-check-label">
      <span *ngIf="!optionTemplate">{{ option.label }}</span>
      <ng-container
        *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"
      ></ng-container>
    </label>
  </div>
</div>
