<div class="card">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <p-tabView [(activeIndex)]="activeTabIndex" styleClass="stepper">
      <p-tabPanel
        *ngFor="let tab of tabs; let i = index"
        [headerStyleClass]="i < activeTabIndex ? 'prev' : ''"
      >
        <ng-template pTemplate="header">
          <div class="tab-header text-center">
            <div class="tab-header-icon-container">
              <div class="tab-header-icon">
                <i class="hi hi-{{tab.icon}}"></i>
              </div>
            </div>
            <h1 class="tab-header-title h6">
              <i *ngIf="i < activeTabIndex" class="hi hi-c-check"></i>
              {{tab.name}}
            </h1>
            <p class="tab-header-desc small">{{tab.description}}</p>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="card-title">
            <div class="d-flex justify-content-between">
              <div class="text-start">
                <i class="hi hi-{{tab.icon}}"></i>
                {{ tab.name }}
              </div>
              <div class="text-end">STEP {{ tab.id }} / {{ tabs.length }}</div>
            </div>
          </div>
          <div class="card-body">
            <ng-container *ngIf="tab.content">
              <ng-template [ngComponentOutlet]="tab.content"></ng-template>
            </ng-container>
          </div>
          <div class="card-footer text-muted">
            <div class="d-flex">
              <div class="p-2">
                <button
                  *ngIf="!isFirst"
                  role="button"
                  (click)="onPrev(i)"
                  class="btn btn-outline-secondary"
                >
                  Back
                </button>
              </div>
              <div *ngIf="!isLast" class="ms-auto p-2">
                <!-- Add logic for validation for each tab -->
                <button role="button" (click)="onNext(i)" class="btn btn-primary">Next Step</button>
              </div>
              <div *ngIf="isLast" class="ms-auto p-2">
                <!-- Add logic for final submission -->
                <button role="button" class="btn btn-primary">Finish</button>
              </div>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </form>
  <!-- For Dev Testing -->
  <!-- <div class="m-4">
    <b>Form Status:</b>
    {{ form.status }} {{ form.touched ? "touched" : "untouched" }} {{ form.dirty ? "dirty" :
    "pristine" }}
    <br />
    <b>Review: </b>
    {{ form.value | json }}
  </div> -->
</div>
