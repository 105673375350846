import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { isSameDay, isWithinInterval } from 'date-fns';
import { PrimeNGConfig } from 'primeng/api';

export interface CalendarDate {
  year: number;
  month: number;
  day: number;
  disabled: boolean;
  today: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-form-date-range-control',
  templateUrl: './form-date-range-control.component.html',
  styleUrls: ['./form-date-range-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormDateRangeControlComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName!: string;
  @Input() label?: string;
  @Input() help!: string;
  @Input() icon?: string;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Output() touch: EventEmitter<void> = new EventEmitter<void>();

  dateRange!: Date[];
  dateFormat: string = 'dd/mm/yy';

  onChange: (value: Date[]) => void = () => {};
  onTouched: () => void = () => {};

  constructor(
    @Self() public controlDir: NgControl,
    private primeNgConfig: PrimeNGConfig,
    private translate: TranslateService
  ) {
    controlDir.valueAccessor = this;
    this.primeNgConfig.setTranslation({
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    });
  }

  ngOnInit() {
    this.translate.get('CONFIGURATIONS.DATEFORMAT').subscribe(text => {
      this.dateFormat = text;
    });
  }

  onDateSelect(): void {
    const startDate: Date = this.dateRange[0];
    const endDate: Date = this.dateRange[1];

    this.onChange([startDate, endDate]);
    this.onTouched();
  }

  getSelectedClass(date: CalendarDate): string {
    const inputDate: Date = new Date(date.year, date.month, date.day);

    let startDate!: Date;
    let endDate!: Date;

    if (this.dateRange) {
      startDate = this.dateRange[0];
      endDate = this.dateRange[1];
    }

    if (startDate && endDate) {
      if (isSameDay(inputDate, startDate)) {
        return 'start-date';
      } else if (isSameDay(inputDate, endDate)) {
        return 'end-date';
      } else if (isWithinInterval(inputDate, { start: startDate, end: endDate })) {
        return 'between-date';
      }
    }

    return '';
  }

  writeValue(value: Date): void {
    value && this.controlDir.control?.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
