import { Component, EventEmitter, Input, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'app-form-text-control',
  templateUrl: './form-text-control.component.html',
  styleUrls: ['./form-text-control.component.scss']
})
export class FormTextControlComponent implements ControlValueAccessor {
  @Input() formControlName!: string;
  @Input() label?: string;
  @Input() help!: string;
  @Input() icon?: string;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Output() touch: EventEmitter<void> = new EventEmitter<void>();
  // @Input() validationMessages?: { [key: string]: string };

  @ViewChild(Tooltip) tooltip!: Tooltip;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  writeValue(value: any): void {
    value && this.controlDir.control?.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
