import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { Inject, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { ShellModule } from '@layouts/shell/shell.module';
import { HttpLoaderFactory } from '@core/translate-loader';
import { LanguageEnum } from '@core/enums';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    SharedModule,
    ShellModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('locale') || LanguageEnum.EN_GB,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    Angulartics2Module.forRoot(),
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(TranslateService) private translateService: TranslateService) {
    this.translateService.addLangs(Object.values(LanguageEnum));
    this.translateService.use(LanguageEnum.EN_GB);
  }
}
