import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  isCollapsed = false;

  constructor() {}

  ngOnInit() {}

  onClose(value: boolean) {
    this.isCollapsed = value;
  }

  onToggle(value: boolean) {
    this.isCollapsed = value;
  }
}
