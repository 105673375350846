import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

import { environment } from '@env/environment';
import { Logger, UntilDestroy } from '@core/services';
import { AuthService } from '@core/services/auth';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private autoLogin!: boolean;

  constructor(
    private authService: AuthService,
    // do not remove the analytics injection, even if the call in ngOnInit() is removed
    // this injection initializes page tracking through the router
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
  ) {
    const queryStringParams = new URLSearchParams(window.location.search);
    this.autoLogin = !!queryStringParams.get('autologin');
  }

  async ngOnInit() {
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // redirection token swap
    const isLoggedIn = await this.authService.isAuthenticated();
    if (this.autoLogin && !isLoggedIn) {
      this.authService.signIn();
    }

    log.debug('init');

    this.angulartics2GoogleAnalytics.startTracking();
    this.angulartics2GoogleAnalytics.eventTrack(environment.version || 'dev', {
      category: 'App initialized'
    });
  }
}
