import { DOCUMENT } from '@angular/common';
import { OnInit, Directive, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFullContent]',
  exportAs: 'appFullContent'
})
export class FullContentDirective implements OnInit, OnDestroy {
  @Input() bodyClass: string = '';
  @Input() rootClass: string = '';

  private CLASS_ATTR = 'class';
  private APP_ROOT = 'app-root';
  private APP_ROOT_MAIN = 'app-root > main';

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (!this.bodyClass || !this.rootClass) {
      throw new Error('body or root class should have value');
    }

    if (this.bodyClass) {
      this.renderer.setAttribute(this.document.body, this.CLASS_ATTR, this.bodyClass);
    }

    if (this.rootClass) {
      this.renderer.setAttribute(
        this.document.querySelector(this.APP_ROOT),
        this.CLASS_ATTR,
        this.rootClass
      );
      this.renderer.setAttribute(
        this.document.querySelector(this.APP_ROOT_MAIN),
        this.CLASS_ATTR,
        this.rootClass
      );
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeAttribute(this.document.body, this.CLASS_ATTR);
    this.renderer.removeAttribute(this.document.querySelector(this.APP_ROOT), this.CLASS_ATTR);
    this.renderer.removeAttribute(this.document.querySelector(this.APP_ROOT_MAIN), this.CLASS_ATTR);
  }
}
