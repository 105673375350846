import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { Auth, AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { Hub } from '@aws-amplify/core';
import { HubClass } from '@aws-amplify/core/lib-esm/Hub';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { DomQueryService, ErrorHandlerInterceptor, OrganisationService } from './services';
import { AuthGuard, AuthService } from './services/auth';
import { PrefixTitleStrategy } from './prefix-title-strategy';
import { ReviewerModule } from '@modules/reviewer/reviewer.module';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    AuthGuard,
    DomQueryService,
    OrganisationService,
    ReviewerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    },
    {
      provide: TitleStrategy,
      useClass: PrefixTitleStrategy
    },
    { provide: AuthClass, useValue: Auth },
    { provide: HubClass, useValue: Hub }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
