<div class="form-group">
  <label *ngIf="label" [for]="formControlName" class="form-label">
    <span>{{label}}</span>
    <i *ngIf="help" class="hi hi-c-question-outline" [pTooltip]="help"></i>
  </label>
  <div class="input-group">
    <span class="input-group-text">
      <i class="{{icon}}"></i>
    </span>
    <input
      class="form-control"
      pInputText
      type="text"
      [placeholder]="placeholder"
      [attr.aria-label]="placeholder"
      [disabled]="disabled"
      [(ngModel)]="value"
      (input)="onInputChange($any($event.target).value)"
    />
  </div>
  <div class="form-input-result" *ngIf="showList">
    <div class="header">
      <h1 class="h5">Found</h1>
      <div class="sub-header">
        <h1 class="search-count">{{filteredItems.length}}</h1>
        <p>{{resultPlaceholder}} for "{{value}}"</p>
      </div>
    </div>
    <div class="content">
      <p-virtualScroller [value]="filteredItems" scrollHeight="200px" [itemSize]="10">
        <ng-template pTemplate="item" let-item> {{ item.label }} </ng-template>
      </p-virtualScroller>
    </div>
  </div>
</div>
