<div class="form-group">
  <label *ngIf="label" [for]="formControlName" class="form-label">
    <span>{{label}}</span>
    <i *ngIf="help" class="hi hi-c-question-outline" [pTooltip]="help"></i>
  </label>
  <div class="form-check-group">
    <div class="form-check form-check-inline" *ngFor="let option of options">
      <input
        id="{{formControlName}}-SDG-{{option.value}}"
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="option.selected"
        (change)="onOptionSelect()"
      />
      <label class="form-check-label" for="{{formControlName}}-SDG-{{option.value}}">
        <i *ngIf="option.selected" class="hi hi-c-check checked"></i>
        <i
          class="hi-sdg hi-sdg-{{option.value}}{{!option.selected ? '-inactive' : ''}}"
          [class.selected]="option.selected"
          [pTooltip]="option.label"
          tooltipPosition="bottom"
        ></i>
      </label>
    </div>
  </div>
</div>
