import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { IOption } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-review-templates',
  templateUrl: './review-templates.component.html',
  styleUrls: ['./review-templates.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ReviewTemplatesComponent implements OnInit {
  @ViewChild('optionTemplate') optionTemplate?: TemplateRef<any>;

  parentForm!: FormGroup;

  // TODO : figure where to fit default and type
  // templates = [
  //   {
  //     id: 1,
  //     type: 'REA',
  //     default: true,
  //     name: 'Research Assessment Exercise',
  //     icon: 'hi hi-research'
  //   },
  //   { id: 2, type: 'THE', default: true, name: 'THE Impact Ranking', icon: 'hi hi-the' },
  //   { id: 3, type: 'THE', default: false, name: 'My Templates', icon: 'hi hi-template' }
  // ];

  templates: IOption[] = [
    {
      label: 'Research Assessment Exercise',
      value: '1',
      icon: 'hi hi-research',
      selected: false
    },
    {
      label: 'THE Impact Ranking',
      value: '2',
      icon: 'hi hi-the',
      selected: false
    },
    {
      label: 'My Templates',
      value: '3',
      icon: 'hi hi-template',
      selected: false
    }
  ];

  customTemplateCount = 3;
  selectedTemplateId?: string;
  selectedDate: any;

  constructor(private parent: FormGroupDirective) {}

  ngOnInit(): void {
    this.parentForm = this.parent.form;
    this.selectedTemplateId = this.templates[1].value;

    this.parentForm.addControl('templateId', new FormControl('', Validators.required));
    this.parentForm.addControl('name', new FormControl('', Validators.required));
    this.parentForm.addControl('period', new FormControl([], Validators.required));
  }
}
