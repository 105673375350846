import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IOption, IReviewer, ReviewerRole } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-add-reviewer-dialog',
  templateUrl: './add-reviewer-dialog.component.html',
  styleUrls: ['./add-reviewer-dialog.component.scss']
})
export class AddReviewerDialogComponent implements OnInit {
  @ViewChild('optionTemplate') optionTemplate?: TemplateRef<any>;

  reviewerForm!: FormGroup;

  roles: IOption[] = [
    { label: 'Contributor', value: 'Contributor', icon: 'hi hi-user-up', selected: false },
    { label: 'Moderator', value: 'Moderator', icon: 'hi hi-user-cogs', selected: false },
    { label: 'Reviewer', value: 'Reviewer', icon: 'hi hi-user-star', selected: false }
  ];

  selectedSdgs: string[];
  selectedReviewer: IReviewer;
  selectedRoles: string[];

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    const { reviewer, sdgs } = config.data;
    this.selectedSdgs = sdgs;
    this.selectedReviewer = reviewer;
    this.selectedRoles = [];
  }

  ngOnInit(): void {
    this.reviewerForm = this.fb.group({
      assignedRoles: [[], Validators.required],
      assignedSdgs: [[], Validators.required]
    });

    this.reviewerForm.get('assignedRoles')?.valueChanges.subscribe(value => {
      this.selectedRoles = value;
    });
  }
}
