import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAmplifyAuth, IAppClient } from '@core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectionService {
  config!: IAmplifyAuth;

  get profileUrl() {
    const domain = this.config.oauth.domain;
    const path = 'autologin=true&returnUrl=/my-profile/preferences';

    return `https://${domain}?${path}`;
  }

  constructor(private http: HttpClient) {
    this.config = environment.cognitoConfig.amplify.Auth;
  }

  async loadApps(): Promise<IAppClient[]> {
    let regionId: string | null = null;

    const clientId = this.config.userPoolWebClientId;
    const domain = this.config.oauth.domain;

    return new Promise<IAppClient[]>((resolve, reject) => {
      this.http
        .get<IAppClient[]>(`https://${domain}/clients-redirection`, {
          params: {
            client_id: clientId,
            region: `${regionId}`
          }
        })
        .subscribe({
          next: result => {
            resolve(result);
          },
          error: error => {
            console.error('Could not get clients. Server response: ' + JSON.stringify(error));
            reject();
          }
        });
    });
  }

  async redirectToApp(app: IAppClient): Promise<Window> {
    return this.urlOpener(app.redirect_uri);
  }

  private urlOpener(url: string, target: string = '_self'): Promise<Window> {
    const windowProxy = window.open(url, target);
    if (windowProxy) {
      return Promise.resolve(windowProxy);
    } else {
      return Promise.reject();
    }
  }
}
