import { IEnvironment } from '@core/models';

export const environment: IEnvironment = {
  production: false,
  version: '',
  cognitoConfig: {
    amplify: {
      Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_iAbKQJy7I',
        userPoolWebClientId: '45d4sojrek3o8uemk3j705u8ha',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
          domain: 'dev-myvvid.com',
          scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://dev-hivveimpacttracker.com',
          redirectSignOut: 'https://dev-hivveimpacttracker.com/logout',
          responseType: 'code'
        }
      }
    }
  }
};
