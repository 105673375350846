<div class="form-group">
  <label *ngIf="label" [for]="formControlName" class="form-label">
    <span>{{label}}</span>
    <i *ngIf="help" class="hi hi-c-question-outline" [pTooltip]="help"></i>
  </label>
  <p-calendar
    inputStyleClass="form-control"
    icon="hi hi-calendar"
    [style]="{'width':'100%'}"
    [inputStyle]="{'width':'100%'}"
    [inline]="false"
    [showIcon]="true"
    selectionMode="range"
    [disabled]="disabled"
    [dateFormat]="dateFormat"
    #calendar
    [(ngModel)]="dateRange"
    (onSelect)="onDateSelect()"
    (onBlur)="onTouched(); touch.emit();"
  >
    <ng-template pTemplate="date" let-date>
      <div [ngClass]="getSelectedClass(date)">{{ date.day }}</div>
    </ng-template>
  </p-calendar>
  <div class="error-message"></div>
</div>
