import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  get version() {
    return environment.version;
  }

  get year() {
    return new Date().getUTCFullYear();
  }
}
