<p-table
  #datatable
  dataKey="id"
  [value]="data"
  styleClass="datatable"
  [rowHover]="true"
  [rows]="rowCount"
  [loading]="loading"
  [paginator]="data.length > rowCount"
  [filterDelay]="0"
  [showCurrentPageReport]="true"
  [responsive]="true"
  currentPageReportTemplate="{first} - {last} of {totalRecords}"
  [globalFilterFields]="filterFields"
  responsiveLayout="stack"
  [showFirstLastIcon]="false"
>
  <ng-template pTemplate="sorticon" let-sortOrder>
    <i class="hi hi-chevron-down hi-sort" *ngIf="sortOrder === 0"></i>
    <i class="hi hi-chevron-up hi-sort" *ngIf="sortOrder === 1"></i>
    <i class="hi hi-chevron-down hi-sort" *ngIf="sortOrder === -1"></i>
  </ng-template>
  <ng-template pTemplate="loadingicon">
    <span class="loader-wrapper">
      <span class="loader">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </span>
    </span>
  </ng-template>
  <ng-template pTemplate="caption" *ngIf="search">
    <div class="d-flex">
      <app-form-search-control
        [filters]="search.filters"
        [icon]="search.icon"
        [placeholder]="search.placeholder"
        (inputChange)="datatable.filterGlobal($event, 'contains')"
      ></app-form-search-control>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let column of columns">
        <th [width]="column.width" *ngIf="column.sortable" [pSortableColumn]="column.field">
          {{column.name}} <p-sortIcon [field]="column.field"></p-sortIcon>
        </th>
        <th [width]="column.width" *ngIf="!column.sortable">{{column.name}}</th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <!-- Custom body template -->
    <ng-container *ngTemplateOutlet="bodyTemplate; context: { $implicit: rowData }"></ng-container>
    <!-- TODO : add logic for catering straight forward data mapping, currently it only cater custom row template -->
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns?.length">No {{name}}(s) found.</td>
    </tr>
  </ng-template>
</p-table>
