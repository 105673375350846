import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IOption } from '@core/models';

@Component({
  selector: 'app-form-checkbox-group-control',
  templateUrl: './form-checkbox-group-control.component.html',
  styleUrls: ['./form-checkbox-group-control.component.scss']
})
export class FormCheckboxGroupControlComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName!: string;
  @Input() label?: string;
  @Input() help!: string;
  @Input() disabled: boolean = false;
  @Input() showCheckbox: boolean = true;
  @Input() optionTemplate: any;
  @Input() options!: IOption[];

  values: string[] = [];

  onChange: (value: string[]) => void = () => {};
  onTouched: () => void = () => {};

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit() {
    if (!this.options) {
      throw new Error('Options is required');
    }
  }

  onOptionSelect() {
    this.values = this.options
      .filter(checkbox => checkbox.selected)
      .map(checkbox => checkbox.value);

    this.onChange(this.values);
    this.onTouched();
  }

  writeValue(value: any): void {
    value && this.controlDir.control?.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
