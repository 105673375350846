import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { ReviewerRoutingModule } from './reviewer-routing.module';
import { SharedModule } from '@shared/shared.module';
import { AssignedSdgsComponent } from './my-reviews/components';
import { MyReviewsComponent } from './my-reviews/my-reviews.component';
import { NewReviewComponent } from './new-review/new-review.component';
import {
  ReviewTemplatesComponent,
  ReviewSdgsComponent,
  ReviewRolesComponent,
  ReviewPublishComponent
} from './new-review/components';
import { AddReviewerDialogComponent } from './new-review/components/review-roles';

@NgModule({
  imports: [SharedModule, Angulartics2Module, ReviewerRoutingModule],
  declarations: [
    MyReviewsComponent,
    AssignedSdgsComponent,
    NewReviewComponent,
    ReviewTemplatesComponent,
    ReviewSdgsComponent,
    ReviewRolesComponent,
    ReviewPublishComponent,
    AddReviewerDialogComponent
  ]
})
export class ReviewerModule {}
