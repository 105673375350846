import { Component } from '@angular/core';
import { IReview, sdgs } from '@core/models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-assigned-sdgs',
  templateUrl: './assigned-sdgs.component.html',
  styleUrls: ['./assigned-sdgs.component.scss']
})
export class AssignedSdgsComponent {
  review: IReview;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
    const { review } = config.data;
    this.review = review;
  }

  getSdgDescription(index: any): string {
    return `${sdgs[index].alias} - ${sdgs[index].name}`;
  }
}
