import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFilter, IOption, ISelectedFilter } from '@core/models';

@Component({
  selector: 'app-form-search-control',
  templateUrl: './form-search-control.component.html',
  styleUrls: ['./form-search-control.component.scss']
})
export class FormSearchControlComponent {
  @Input() icon?: string = 'hi hi-search';
  @Input() placeholder?: string = 'Search...';

  @Input() filters: IFilter[] = [];

  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() filterChange: EventEmitter<ISelectedFilter[]> = new EventEmitter<ISelectedFilter[]>();

  selectedFilters: IFilter[] = [];

  constructor() {}

  toggleFilter(name: string, option: IOption) {
    const filter = this.filters.find(f => f.name === name);
    if (!filter) {
      return;
    }

    option.selected = !option.selected;
    const selectedFilter = this.selectedFilters.find(sf => sf.name === name);

    if (option.selected) {
      if (selectedFilter) {
        selectedFilter.options.push(option);
      } else {
        this.selectedFilters.push({ name, options: [option] });
      }
    } else if (selectedFilter) {
      const index = selectedFilter.options.indexOf(option);
      if (index > -1) {
        selectedFilter.options.splice(index, 1);
      }
      if (selectedFilter.options.length === 0) {
        const selectedIndex = this.selectedFilters.indexOf(selectedFilter);
        if (selectedIndex > -1) {
          this.selectedFilters.splice(selectedIndex, 1);
        }
      }
    }
  }

  getFilterText(name: string): string {
    const filter = this.filters.find(f => f.name === name);

    let selectedFilter;
    if (filter) {
      selectedFilter = this.selectedFilters.find(sf => sf.name === name);
    }

    if (selectedFilter && selectedFilter.options.length === 1) {
      const selectedOption = selectedFilter.options[0];
      return selectedOption ? selectedOption.label : '';
    }

    if (selectedFilter && selectedFilter.options.length > 1) {
      return `${name} +${selectedFilter.options.length}`;
    }

    return name;
  }

  isSelected(name: string, option: IOption): boolean {
    const selectedFilter = this.selectedFilters.find(sf => sf.name === name);
    return !!selectedFilter && selectedFilter.options.includes(option);
  }

  onInput(event: Event) {
    const element = event.target as HTMLInputElement;
    this.inputChange.emit(element.value);
  }
}
