export interface IOption {
  label: string;
  value: string;
  icon?: string;
  selected: boolean;
}

export interface IFilter {
  name: string;
  field?: string;
  options: IOption[];
}

export interface ISelectedFilter {
  name: string;
  filter: string[];
}
