import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ITableColumn, ITableSearch } from '@core/models';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DataTableComponent implements OnInit {
  @Input() search?: ITableSearch;
  @Input() columns?: ITableColumn[] = [];
  @Input() data: any[] = [];
  @Input() bodyTemplate: any;
  @Input() rowCount = 3;
  @Input() loading = true;
  @Input() name = 'data';

  @ViewChild('datatable', { static: false }) table?: Table;

  filterFields: string[] = [];

  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit(): void {
    if (!this.columns) {
      throw new Error('Column definition is not defined.');
    }

    if (this.search) {
      const filters = this.search.filters
        .map(filter => filter.name)
        .filter((name): name is string => typeof name !== undefined);

      this.filterFields = [this.search.field, ...filters];
    }

    this.primengConfig.ripple = true;
  }
}
