import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReview, IReviewTemplate, IReviewer } from '@core/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewerService {
  constructor(private http: HttpClient) {}

  getMyReviews(): Observable<IReview[]> {
    return this.http.get<IReview[]>('assets/tests/ir-reviews.json').pipe(map(res => res));
  }

  getTemplates(): Observable<IReviewTemplate[]> {
    return this.http
      .get<any>('assets/tests/ir-templates.json')
      .pipe(map(res => <IReviewTemplate[]>res.templates));
  }

  getSuggestedReviewer(): Observable<IReviewer[]> {
    return this.http
      .get<IReviewer[]>('assets/tests/ir-suggested-reviewers.json')
      .pipe(map(res => res));
  }
}
