import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IOrganisationMenu } from '@core/models';
import { OrganisationService } from '@core/services';

@Component({
  selector: 'app-organisation-dropdown',
  templateUrl: './organisation-dropdown.component.html',
  styleUrls: ['./organisation-dropdown.component.scss']
})
export class OrganisationDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('dropdown', { static: false }) dropdown?: ElementRef;

  selectedClass = '.dropdown-toggle';
  defaultOrgImage = 'assets/images/defaults/institution.svg';

  organisations: IOrganisationMenu[] = [];
  selectedOrganisation?: IOrganisationMenu;

  constructor(private orgService: OrganisationService) {}

  ngOnInit() {
    this.orgService.getOrganisationMenu().subscribe(organistaions => {
      this.organisations = organistaions;
      // assume to select first index
      this.selectedOrganisation = this.organisations[0];
    });
  }

  ngAfterViewInit() {
    const dropdownToggle = this.dropdown?.nativeElement.querySelector(this.selectedClass);
    if (dropdownToggle) {
      dropdownToggle.textContent = this.selectedOrganisation?.name;
    }
  }

  onSelectedChange(option: IOrganisationMenu) {
    this.selectedOrganisation = option;
    const dropdownToggle = this.dropdown?.nativeElement.querySelector(this.selectedClass);

    if (dropdownToggle) {
      dropdownToggle.textContent = option.name;
    }
  }
}
