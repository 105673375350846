import { IFilter } from './form-control.model';

export interface ITable {
  search: ITableSearch;
  columns: ITableColumn[];
  rows: ITableRow[];
}

export interface ITableSearch {
  icon?: string;
  field: string;
  placeholder: string;
  filters: IFilter[];
}

export interface ITableColumn {
  name: string;
  width?: number;
  field: string;
  sortable: boolean;
}

export interface ITableRow {}
