import { Component, OnInit } from '@angular/core';
import { IOption, IReviewer } from '@core/models';
import { ReviewerService } from '@core/services/reviewer';
import { DialogService } from 'primeng/dynamicdialog';
import { AddReviewerDialogComponent } from './add-reviewer-dialog/add-reviewer-dialog.component';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-review-roles',
  templateUrl: './review-roles.component.html',
  styleUrls: ['./review-roles.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ReviewRolesComponent implements OnInit {
  parentForm!: FormGroup;
  suggestedReviewers: IReviewer[] = [];
  options: IOption[] = [
    {
      label: 'John Smith',
      value: '1',
      selected: false
    },
    {
      label: 'Jane Smith',
      value: '2',
      selected: false
    }
  ];

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private dialogService: DialogService,
    private reviewerService: ReviewerService
  ) {}

  onOpenDialog(reviewer: IReviewer): void {
    const selectedSdgs = this.parentForm.get('sdgs')?.value;
    const ref = this.dialogService.open(AddReviewerDialogComponent, {
      width: '32%',
      showHeader: false,
      contentStyle: { overflow: 'hidden' },
      closable: true,
      data: {
        reviewer: reviewer,
        sdgs: selectedSdgs
      }
    });
  }

  ngOnInit(): void {
    this.parentForm = this.parent.form;
    this.parentForm.addControl(
      'participants',
      this.fb.group({
        collaborators: [[], Validators.required],
        moderators: [[], Validators.required],
        reviewers: [[], Validators.required]
      })
    );

    this.reviewerService.getSuggestedReviewer().subscribe(reviewers => {
      this.suggestedReviewers = reviewers;
    });
  }

  // TODO : add tables for roles
  // TODO : get the ref value from the modal and pass this to the participants field
}
