<div class="form-group">
  <label *ngIf="label" [for]="formControlName" class="form-label">
    <span>{{label}}</span>
    <i *ngIf="help" class="hi hi-c-question-outline" [pTooltip]="help"></i>
  </label>
  <div class="form-check-group">
    <div class="form-check form-check-inline" *ngFor="let option of options">
      <input
        id="{{formControlName}}-{{option.value}}"
        type="checkbox"
        class="form-check-input"
        [class.form-check-input-hide]="!showCheckbox"
        [(ngModel)]="option.selected"
        (change)="onOptionSelect()"
      />
      <label class="form-check-label" for="{{formControlName}}-{{option.value}}">
        <span *ngIf="!optionTemplate">{{option.label}}</span>
        <ng-container
          *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"
        ></ng-container>
      </label>
    </div>
  </div>
</div>
