import { Component, Input, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IOption } from '@core/models';

@Component({
  selector: 'app-form-radio-control',
  templateUrl: './form-radio-control.component.html',
  styleUrls: ['./form-radio-control.component.scss']
})
export class FormRadioControlComponent implements ControlValueAccessor {
  @Input() formControlName!: string;
  @Input() label?: string;
  @Input() help!: string;
  @Input() disabled: boolean = false;
  @Input() showRadio: boolean = true;
  @Input() optionTemplate: any;
  @Input() options!: IOption[];

  value!: string;

  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  onOptionSelect(option: IOption) {
    this.value = option.value;

    this.options.forEach(x => {
      x.selected = x.value == option.value;
    });

    this.onChange(this.value);
    this.onTouched();
  }

  writeValue(value: any): void {
    value && this.controlDir.control?.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
