export interface INavigationMenu {
  name: string;
  label: string;
  i18n: string;
  route?: string;
  styleClass?: string;
  icon: string;
  hasCounter?: boolean;
  subMenus?: INavigationSubMenu[];
}

export interface INavigationSubMenu {
  name: string;
  label: string;
  i18n: string;
  route: string;
  icon?: string;
}

export interface IOrganisationMenu {
  id: number;
  name: string;
  logoUrl: string;
  features: IOrganisationMenuFeature[];
}

export interface IOrganisationMenuFeature {
  id: number;
  name: string;
  icon: string;
  route: string;
}
