import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { Amplify } from '@aws-amplify/core';
import { IAuthUser, IAuthUserInfo } from '@core/models';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _authUser = new BehaviorSubject<IAuthUser | null>(null);

  get authUser() {
    return this._authUser.value;
  }

  set authUser(value: IAuthUser | null) {
    this._authUser.next(value);
  }

  get authUserInfo(): IAuthUserInfo | undefined {
    if (!this.authUser?.attributes) {
      return undefined;
    }

    const { sub, email, identities, 'custom:region': region } = this.authUser.attributes;
    let termsAccepted: boolean = JSON.parse(
      this.authUser.attributes['custom:termsAccepted'] || 'false'
    );
    let phoneNumberVerified: boolean = JSON.parse(
      this.authUser.attributes['phone_number_verified'] || 'false'
    );

    return { id: sub, email, identities, region, termsAccepted, phoneNumberVerified };
  }

  constructor(private amplifyAuth: AuthClass, private router: Router) {
    Amplify.configure(environment.cognitoConfig.amplify);
    this.isAuthenticated();
  }

  signIn(): void {
    this.amplifyAuth.federatedSignIn();
  }

  async signOut(): Promise<void> {
    try {
      await this.amplifyAuth.signOut();
      this.authUser = null;
      this.router.navigate([''], { queryParamsHandling: 'merge' });
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      this.authUser = await this.amplifyAuth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      this.authUser = null;
      console.warn('loadAuthSessionError: ', e);
    }
    return false;
  }
}
