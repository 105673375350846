import { CognitoUser } from '@aws-amplify/auth';

export interface ICognitoConfig {
  amplify: { Auth: IAmplifyAuth };
}

export interface IAmplifyAuth {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType?: string;
  oauth: {
    domain: string;
    scope: string[];
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };
}

export interface IAuthUser extends CognitoUser {
  attributes: {
    sub: string;
    email: string;
    identities: string;
    'custom:region': string;
    'custom:termsAccepted': string;
    phone_number_verified: string;
  };
}

export interface IAuthUserInfo {
  id: string;
  email: string;
  identities: string;
  region: string;
  termsAccepted: boolean;
  phoneNumberVerified: boolean;
}

export interface IAppClient {
  client_id: string;
  client_name: string;
  client_logo: string;
  redirect_uri: string;
  order: number;
}
