<div class="d-flex flex-wrap whitespace mb-5">
  <div class="whitespace-col-1 flex-grow mb-3 mb-lg-0 order-sm-2 order-md-1">
    <div class="whitespace-text">
      <h1 class="whitespace-title h5">Which SDGs would you like to collect data for?</h1>
      <p class="whitespace-desc">
        Our predefined THE Impact Ranking template includes a predefined set of scoring questions
        for each SDG.
      </p>
    </div>
  </div>
  <div class="whitespace-col-2 flex-grow order-sm-1 order-md-2">
    <img alt="step-4" src="assets/images/reviewer/illustration-step-2.svg" class="whitespace-img" />
  </div>
</div>
<div class="row">
  <div class="col-lg-10 col-xs-12 col-sm-12">
    <app-form-sdgs-control
      label="Select the SDGs that relate to your project"
      formControlName="sdgs"
    ></app-form-sdgs-control>
  </div>
</div>
