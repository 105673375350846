<ng-content select="[indicator]"></ng-content>
<ng-container *ngIf="!hasError">
  <img
    alt="avatar"
    class="img-fluid"
    [src]="(avatar?.url || url) || srcImg"
    appImageFallback
    (hasErrorEvent)="onError($event)"
  />
</ng-container>
<ng-container *ngIf="isInitial && hasError">
  <div class="initial">{{initial}}</div>
</ng-container>
